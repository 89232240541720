import {useCallback} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  AssigneeType,
  Collections,
  createAuditNonce,
  getAssigneeName,
  Profile,
  Ticket,
  TicketAssignee,
  TicketHistoryRecordType,
  TicketStatus,
  TicketStatusName,
  useUserInfo,
} from '../../..';
import {Firebase} from '../../../firebase';
import {FIELD_NAME_ASSIGNEE, FIELD_NAME_STATUS} from '../constants/constants';
import {useGroupsOptions} from '../hooks/useGroupsOptions';
import {useHistoryRecord} from '../hooks/useHistoryRecord';

const useTicketUpdate = (ticketId?: string) => {
  const {uid, isPortal, userDisplayName} = useUserInfo();

  const {groups} = useGroupsOptions();

  const {clearErrors, setValue} = useFormContext();

  const assignee: TicketAssignee | undefined = useWatch({name: FIELD_NAME_ASSIGNEE});
  const status: TicketStatus | undefined = useWatch({name: FIELD_NAME_STATUS});

  const {addHistoryRecord} = useHistoryRecord();

  const updateStatus = useCallback(
    async (nextStatus: TicketStatus) => {
      if (!ticketId) {
        return;
      }

      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const newStatusUpdate: Ticket = {
        status: nextStatus,
        updatedAt: Firebase.now(),
        auditNonce,
      };

      if (nextStatus === TicketStatus.Open) {
        newStatusUpdate.openedAt = Firebase.now();
        // Save info about the original Assignee Department
        await addHistoryRecord(
          `Assignee Changed to ${getAssigneeName(assignee)}`,
          TicketHistoryRecordType.Assignee,
          true,
          `Assignee Changed to ${getAssigneeName(assignee, false)}`,
          true
        );
      }

      if ([TicketStatus.Closed, TicketStatus.Unresolved].includes(nextStatus)) {
        newStatusUpdate.resolvedAt = Firebase.now();
      }

      // update adminsNotifiedAt to the current time for continue sending notification after 24 hours of inactivity
      if (status === TicketStatus.Unresolved) {
        newStatusUpdate.adminsNotifiedAt = Firebase.now();
      }

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set(newStatusUpdate, {merge: true});

      setValue(FIELD_NAME_STATUS, nextStatus, {shouldValidate: false});

      await addHistoryRecord(
        `Status Change to ${TicketStatusName[nextStatus]}`,
        TicketHistoryRecordType.Status,
        true
      );
    },
    [addHistoryRecord, setValue, status, ticketId]
  );

  const updateAssignee = useCallback(
    async (nextAssignee?: TicketAssignee) => {
      if (!ticketId) {
        return;
      }

      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .update({assignee: nextAssignee, updatedAt: Firebase.now(), auditNonce});

      setValue(FIELD_NAME_ASSIGNEE, nextAssignee);

      if (
        nextAssignee?.type === AssigneeType.erpDepartment &&
        assignee?.type !== AssigneeType.erpDepartment
      ) {
        await updateStatus(TicketStatus.Open);
      }

      if (nextAssignee) {
        clearErrors(FIELD_NAME_ASSIGNEE);
        await addHistoryRecord(
          `Assignee Changed to ${getAssigneeName(nextAssignee)}`,
          TicketHistoryRecordType.Assignee,
          undefined,
          `Assignee Changed to ${getAssigneeName(nextAssignee, false)}`
        );
      }
    },
    [assignee?.type, clearErrors, setValue, ticketId, updateStatus]
  );

  const updateAssigneeToMe = useCallback(
    async (assigneeDraft?: TicketAssignee) => {
      if (isPortal) {
        await updateAssignee({
          type: AssigneeType.agent,
          group: groups[0],
          agent: {id: uid, name: userDisplayName},
        } as TicketAssignee);
      } else {
        const profileSnapshot = await Firebase.firestore
          .collection(Collections.profiles)
          .doc(uid)
          .get();

        const profile = profileSnapshot.data() as Profile;

        await updateAssignee({
          ...assigneeDraft,
          type: AssigneeType.erpUser,
          erpUser: {id: uid, name: userDisplayName, department: profile.department},
        } as TicketAssignee);
      }

      await updateStatus(TicketStatus.InProgress);
      //update openedAt explicitly because we skip Open status for that case
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set({openedAt: Firebase.now()}, {merge: true});
    },
    [groups, userDisplayName, updateAssignee, updateStatus]
  );

  return {updateAssignee, updateAssigneeToMe, updateStatus} as const;
};

export {useTicketUpdate};
