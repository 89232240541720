import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {IconButton, Link, Typography, useMediaQuery, useTheme} from '@mui/material';
import {useAgent, useAgentScheduleA} from '@ozark/common';
import {Loading, ScheduleA, Title} from '@ozark/common/components';
import {useUserInfo} from '@ozark/common/hooks/useUserInfo';
import {useState} from 'react';
import {useHistory, useParams} from 'react-router';
import {AGENTS} from '../../constants/routes';
import {useStore} from '../../store/helpers';
import {Tab, Tabs} from '../Tabs';
import {AgentEdit} from './Edit';

const Agent = () => {
  const {authProfile, isUserAdmin, isUserGroupAdmin, group} = useStore();
  const {isAgent, userGroupId, uid} = useUserInfo();
  const history = useHistory();
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('md'));
  const [tab, setTab] = useState(0);
  const {id} = useParams<{id: string}>();
  const {
    document: {data: agent},
  } = useAgent(id);

  const {document: scheduleA, addNewVersion: setScheduleA} = useAgentScheduleA(id);

  const isAdmin = isUserAdmin();
  const isGroupAdmin = isUserGroupAdmin();

  const isGroupOwner = authProfile.data?.isGroupOwner;
  const isSubAgentOfCurrentUser = !!agent && agent.masterUid === authProfile.data?.id;
  const isSubAgent = !!agent && !!agent.masterUid;

  const isOwnAgentProfile = isAgent && !!uid && !!id && id === uid;

  const isSameGroup = agent?.group?.id === userGroupId;

  const agentAdminScheduleAEditable = !isOwnAgentProfile && isGroupAdmin && isSameGroup;

  const agentMemberScheduleAEditable =
    !isOwnAgentProfile && !isGroupAdmin && isSubAgentOfCurrentUser && isSameGroup;

  const canEditScheduleA = agentAdminScheduleAEditable || agentMemberScheduleAEditable;

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  if (!agent || authProfile.promised) return <Loading />;

  return (
    <div>
      <Title
        breadcrumbs={
          smDown
            ? [
                <IconButton onClick={() => history.goBack()} size="large">
                  <ChevronLeftIcon />
                </IconButton>,
              ]
            : [
                <Link component="button" variant="body1" onClick={() => history.push(AGENTS)}>
                  Agents
                </Link>,
                <Typography variant="body1">{`${agent.firstName} ${agent.lastName}`}</Typography>,
              ]
        }
      >
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Agent Information" />
          <Tab label="Schedule A" disabled={scheduleA.promised} />
        </Tabs>
      </Title>

      {tab === 0 && <AgentEdit agentId={agent.id} isAuthUserAdmin={isAdmin} />}
      {tab === 1 && !scheduleA.promised && (
        <ScheduleA
          document={scheduleA.data}
          readonly={!canEditScheduleA}
          parentScheduleAParams={
            isSubAgent
              ? {
                  id: agent.masterUid,
                  type: 'agent',
                }
              : isGroupOwner || isGroupAdmin
              ? {
                  id: agent.group.id,
                  type: 'group',
                }
              : undefined
          }
          set={setScheduleA}
          applyDefaults={isSubAgentOfCurrentUser || isGroupOwner || isGroupAdmin}
          riskTogglesEnabled={false}
          displayCalculationMethod={isGroupAdmin}
          disableAgentCalculationMethod={
            isOwnAgentProfile ||
            !group.data?.applicationSettings?.allowAgentsUpdateResidualCalculation
          }
        />
      )}
    </div>
  );
};

export default Agent;
